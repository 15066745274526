import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../../../utill/auth";

const AdminHeader = memo((props) => {
//   const dispatch = useDispatch()
// const handleToggle = ()=> {
//   dispatch(toggleAction())
// }
const navigate = useNavigate()
  const authUser= auth.getUserInfo()
//  //console.log(props);
const handleLogout = () => {
  localStorage.clear();
  navigate(`/login`)
};
  return (
    
    <div>
      <nav
        className="navbar navbar-main navbar-expand px-0 mx-4 shadow-none border-radius-xl"
        id="navbarBlur"
        data-scroll="true"
      >
        <div className="container-fluid py-1 px-3">
          {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5 align-items-center">
              <li className="breadcrumb-item text-sm">
                <NavLink className="" to="/admin/dashboard">
                  Pages
                </NavLink>
              </li>

              <li className="breadcrumb-item text-sm">
              
                  <div className="d-flex">
                    <li className="breadcrumb-item text-sm">
                      
                    <NavLink
                    className=""
                    to={props.link1}
                    style={(isActive) => ({
                      color: isActive ? "#e91e63" : "",
                    })}
                  >
                    {props.name1}
                  </NavLink>
                    </li>
                   {props.name2 && <li className="breadcrumb-item text-sm">
                      
                      <NavLink
                        style={(isActive) => ({
                          color: isActive ? "#e91e63" : "",
                        })}
                        className=""
                        to={props.name2}
                      >
                       {props.name2}
                      </NavLink>
                    </li>
                }
                  </div>

              </li>
            </ol>
          </nav> */}
           <div
            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
              {/* <div className="input-group input-group-outline">
                <label className="form-label">Type here...</label>
                <input type="text" className="form-control" />
              </div> */}
            </div>
            <ul className="navbar-nav justify-content-end">
            {authUser && 
                 <li onClick={handleLogout} className="nav-link text-body font-weight-bold px-0 nav-item d-flex align-items-center">
                
                <div > <i className="fa fa-user me-sm-1"></i> <span className="text-black me-2">{authUser?.first_name}! </span></div>
               
                   <span className="d-inline cursor-pointer " >Sign Out</span>
                 
               </li>
               
                 
                }
              
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <button
                  onClick={() => props.setissidebarOpen(toggle=> !toggle)}
                  // onClick={handleToggle}
                
                  className="nav-link text-body p-0"
                  id="iconNavbarSidenav"
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </button>
              </li>
              {/* <li className="nav-item px-3 d-flex align-items-center">
                <Link  className="nav-link text-body p-0">
                  <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                </Link>
              </li>
              <li className="nav-item dropdown pe-2 d-flex align-items-center">
                <Link
                  
                  className="nav-link text-body p-0"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-bell cursor-pointer"></i>
                </Link>
               
              </li> */}
            </ul>
          </div> 
        </div>
      </nav>
    </div>
  );
});

export default AdminHeader;

