import React, { memo } from "react";
import { NavLink } from "react-router-dom";

const PageHeader = memo(({ link1, link2, link3, name1, name2, name3 }) => {
  return (
 
      <nav
        className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
        id="navbarBlur"
        data-scroll="true"
      >
        <div className="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5 align-items-center ">
              <li className="breadcrumb-item text-sm">Pages</li>

              <li className="breadcrumb-item text-sm">
                {/* <div className="d-flex">
                    <li className="breadcrumb-item text-sm"> */}

                <NavLink
                  className="text-capitalize"
                  to={link1}
                  style={({ isActive }) => ({
                    color: isActive ? "#059dc0" : "",
                  })}
                >
                  {name1}
                </NavLink>
                {/* </li> */}
              </li>
              {name2 && (
                <li className="breadcrumb-item text-sm">
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#059dc0" : "",
                    })}
                    className="text-capitalize"
                    to={link2}
                  >
                    {name2}
                  </NavLink>
                </li>
              )}
              {name3 && (
                <li className="breadcrumb-item text-sm">
                  <NavLink
                    style={(isActive) => ({
                      color: isActive ? "#059dc0" : "",
                    })}
                    className="text-capitalize"
                    to={link3}
                  >
                    {name3}
                  </NavLink>
                </li>
              )}
              {/* </div>

              </li> */}
            </ol>
          </nav>
        </div>
      </nav>
   
  );
});

export default PageHeader;
