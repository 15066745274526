import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import AdminHeader from "../../components/admin/layout/AdminHeader";
import AdminSidebar from "../../components/admin/layout/AdminSidebar";
import "../../css/material-dashboard.css";
const AdminLayout = memo(({ children }) => {
  const [issidebarOpen, setissidebarOpen] = useState(false);
  // false & sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark ps

  return (
    <div
      className={
        issidebarOpen
          ? "g-sidenav-show bg-gray-200 g-sidenav-pinned"
          : "g-sidenav-show bg-gray-200 "
      }
    >
      <AdminSidebar
        issidebarOpen={issidebarOpen}
        setissidebarOpen={setissidebarOpen}
      />
      <div className="main-content position-relative min-vh-100 h-100 border-radius-lg">
      <AdminHeader
        issidebarOpen={issidebarOpen}
        setissidebarOpen={setissidebarOpen}
      />
      
        <div>
          <div>{children}</div>
        </div>
      </div>
        <footer className="footer py-4">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-lg-6 mb-lg-0 mb-4">
                <div className="copyright text-center text-sm text-muted text-lg-start">
                <small >© Copyright JRK International Ltd. 2023. All Rights Reserved</small>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                  <li className="nav-item">
                    <Link
                     to='/'
                      className="nav-link text-muted"
                      target="_blank" rel="noreferrer"
                    >
                      JRK International LTD.
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                     to='/about'
                      className="nav-link text-muted"
                      target="_blank" rel="noreferrer"
                    >
                      About Us
                    </Link>
                  </li>
               
                
                </ul>
              </div>
            </div>
          </div>
        </footer>
    </div>
  );
});

export default AdminLayout;

