import * as actionType from '../types';

const initialState = {
    btnState: true,
  };

  export const toggleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.Toggle_BUTTON:
          // console.log(state.btnState);
        return {
          ...state,
          btnState: !state.btnState
        };
     
      default:
        return state;
    }
  };
  
