import React, { memo } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const AdminSidebar = memo(({ issidebarOpen, setissidebarOpen }) => {
  // const {toggleBtn} = useSelector( (state) => state.toggleBtn)
  // console.log({toggleBtn});
  const location = useLocation();
  const currenturl = location.pathname;
  // console.log(currentURL);
  return (
    <div
    >
      {/* {issidebarOpen === true && ( */}
        <aside
          className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
          id="sidenav-main"
        >
          <div className="sidenav-header">
            <button
              className="d-block d-xl-none"
              onClick={() => setissidebarOpen(false)}
            >
              <i
                className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 "
                // aria-hidden="true"
                id="iconSidenav"
              ></i>
            </button>

            <div className="navbar-brand m-0">
              {/* <!-- <img loading="lazy" src="../assets/img/logo.png" className="navbar-brand-img h-100" alt="main_logo"> --> */}
              <Link to='/' className=" font-weight-bold text-white">
                {/* JRK International Ltd. */}
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" viewBox="0 0 60 70">
      <path
        fill="#059dc0"
        d="M22.97 32.801a12.5 12.5 0 01-.11 2 3.39 3.39 0 01-.84 1.73 3.5 3.5 0 01-2.71 1.06h-2.4v-3h1.49a1.07 1.07 0 00.9-.34 1.85 1.85 0 00.27-1.15v-3.2h3.4v2.9zm0-4.66h-3.4v-3h3.4v3z"
      ></path>
      <path
        fill="#e88144"
        d="M32.97 28.45a2.93 2.93 0 01-2.61 3.09 2.52 2.52 0 011.57.87 3 3 0 01.48 1.84v3.27h-3.44V34.6a1.71 1.71 0 00-.44-1.35 2.5 2.5 0 00-1.56-.37h-2.89v-3h4c.88 0 1.32-.3 1.32-.91s-.4-.89-1.19-.89h-4.13v-3h4.26a9.45 9.45 0 012.28.21 3.26 3.26 0 011.38.75 3.12 3.12 0 01.97 2.41z"
      ></path>
      <path
        fill="#059dc0"
        d="M41.9 37.521h-4.39l-3.95-5v-2.62l3.95-4.77h4.05l-4.92 5.91 5.26 6.48z"
      ></path>
      <path
        fill="#d9dce1"
        d="M20.5 16.799s23.84-17.52 35.09-4.21c0 0-1.39 18-17.33 28.68 0 0 11.91-8.55 10-21 0 0-2.14-9.86-15.94-7.47.02 0-7.64 1.37-11.82 4z"
      ></path>
      <path
        fill="#f3b54c"
        d="M28.82 45.86S57.97 39.12 57.29 0c0 0 6.77 12.84-1.2 24.59S44.37 40.36 28.82 45.86z"
      ></path>
      <path
        fill="#d8dce1"
        d="M58.14 22.119s-8.05 17.34-26.78 23.68-17.59-8.8-17.59-8.8-4.8 11.74 14.79 17c0 0 26.58-8.84 29.58-31.88z"
      ></path>
      <path
        fill="#e26156"
        d="M18.42 27.24S6.48 39.4 13.27 50.9c0 0 3.39 7 14.9 6.69 0 0 9.31-.44 12.27-1.21 0 0-12.64-.66-21.22-5.73 0 0-6.21-3.62-6.32-9.13 0 0-.93-6.39 5.52-14.28z"
      ></path>
      <path
        fill="#059dc0"
        d="M47.97 21.1s-2-8.76-15.12-6.08c0 0-14.53 2.75-32.85 15.89 0 0 15.28-13 28.92-16.59-.03 0 15.15-5.08 19.05 6.78z"
      ></path>
    </svg>
    JRK International Ltd.
              </Link>
            </div>
          </div>
          <hr className="horizontal light mt-0 mb-2" />
          <div
            className=""
            // collapse navbar-collapse w-100
            id="sidenav-collapse-main"
          >
            <ul className="navbar-nav">
            <li className="nav-item">
                <NavLink className="nav-link text-white " to="/admin/dashboard">
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1">Dashboard</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link text-white " to="/admin/user-role">
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">table_view</i>
                  </div>
                  <span className="nav-link-text ms-1">User Role</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={
                    currenturl.includes(`/add-product`) ||
                    currenturl.includes(`/edit-product`) ||
                    currenturl.includes(`/product-details/`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  } to="/admin/products">
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">table_view</i>
                  </div>
                  <span className="nav-link-text ms-1">Products</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-service`) ||
                    currenturl.includes(`/edit-service`) ||
                    currenturl.includes(`/service-details/`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/service-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">receipt_long</i>
                  </div>
                  <span className="nav-link-text ms-1">Services</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={
                    currenturl.includes(`/add-member`) ||
                    currenturl.includes(`/edit-member`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  } to="/admin/team-list">
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">view_in_ar</i>
                  </div>
                  <span className="nav-link-text ms-1">Team</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/testimonial-details/`) ||
                    currenturl.includes(`/edit-testimonial/`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/testimonial-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">
                      format_textdirection_r_to_l
                    </i>
                  </div>
                  <span className="nav-link-text ms-1">Testimonial</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-job`) ||
                    currenturl.includes(`/edit-job`) ||
                    currenturl.includes(`/job-details`) ||
                    currenturl.includes(`/applicant-list`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to={`/admin/job-list/?state=posted`}
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">
                      format_textdirection_r_to_l
                    </i>
                  </div>
                  <span className="nav-link-text ms-1">Job </span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-notice`) ||
                    currenturl.includes(`/edit-notice`) ||
                    currenturl.includes(`/notice-details`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/notice-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">notifications</i>
                  </div>
                  <span className="nav-link-text ms-1">Notice </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/contact-details`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/contact-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">receipt_long</i>
                  </div>
                  <span className="nav-link-text ms-1">Contact </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-partner`) ||
                    currenturl.includes(`/edit-partner`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/partner-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">diversity_3</i>
                  </div>
                  <span className="nav-link-text ms-1">Partner </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-address`) ||
                    currenturl.includes(`/edit-address`) ||
                    currenturl.includes(`/address-details/`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/address-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">location_on</i>
                  </div>
                  <span className="nav-link-text ms-1">Company Address </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-category`) ||
                    currenturl.includes(`/edit-category`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/category-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">category</i>
                  </div>
                  <span className="nav-link-text ms-1">Category </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-subcategory`) ||
                    currenturl.includes(`/edit-subcategory`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/subcategory-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dynamic_feed</i>
                  </div>
                  <span className="nav-link-text ms-1">Subcategory </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className={
                    currenturl.includes(`/edit-order`) ||
                    currenturl.includes(`/order-details/`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  } to="/admin/order-list">
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dynamic_feed</i>
                  </div>
                  <span className="nav-link-text ms-1">Order</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-news-letter`) ||
                    currenturl.includes(`/edit-news-letter`) ||
                    currenturl.includes(`/newsletter-details/`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/news-letter-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dynamic_feed</i>
                  </div>
                  <span className="nav-link-text ms-1">News letter </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className= "nav-link text-white"
                  
                  to="/admin/subscribe-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">dynamic_feed</i>
                  </div>
                  <span className="nav-link-text ms-1">Subscriber </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-question-type`) ||
                    currenturl.includes(`/edit-question-type`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/question-type-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">
                      format_list_bulleted{" "}
                    </i>
                  </div>
                  <span className="nav-link-text ms-1">Question Type </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                 className={
                  currenturl.includes(`/add-single-question`) ||
                  currenturl.includes(`/edit-single-question`) ||
                  currenturl.includes(`/single-question-details/`)
                    ? "nav-link text-white nav-link-active"
                    : "nav-link text-white"
                }
                  to="/admin/single-question-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">history_edu</i>
                  </div>
                  <span className="nav-link-text ms-1">Single Question </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-exam-question`) ||
                    currenturl.includes(`/edit-exam-question`) ||
                    currenturl.includes(`/exam-question-details`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/exam-question-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">description </i>
                  </div>
                  <span className="nav-link-text ms-1">Exam Question</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/applicantAns-details`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/applicants-submit-answer"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">description </i>
                  </div>
                  <span className="nav-link-text ms-1">Applicants Answer</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-footer-header`) ||
                    currenturl.includes(`/edit-footer-header`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/footer-header-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">tune </i>
                  </div>
                  <span className="nav-link-text ms-1">Footer Header</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                 className={
                  currenturl.includes(`/add-footer-content`) ||
                  currenturl.includes(`/edit-footer-content`) 
                    ? "nav-link text-white nav-link-active"
                    : "nav-link text-white"
                }
                  to="/admin/footer-content-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">tune </i>
                  </div>
                  <span className="nav-link-text ms-1">Footer Content</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-header-menu`) ||
                    currenturl.includes(`/edit-header-menu`)
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/header-menu-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">tune </i>
                  </div>
                  <span className="nav-link-text ms-1">Header Menu</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-about-feature`) ||
                    currenturl.includes(`/edit-about-feature`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/about-feature-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">tune </i>
                  </div>
                  <span className="nav-link-text ms-1">
                    Client About Feature
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                   
                    currenturl.includes(`/add-partner-count`) ||
                    currenturl.includes(`/edit-partner-count`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/partner-count-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">tune </i>
                  </div>
                  <span className="nav-link-text ms-1">Partner Count</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                   
                    currenturl.includes(`/add-consulting-step`) ||
                    currenturl.includes(`/consulting-step-details`) ||
                    currenturl.includes(`/edit-consulting-step`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/consulting-step-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">tune </i>
                  </div>
                  <span className="nav-link-text ms-1">CMS Consulting Steps</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={
                    currenturl.includes(`/add-it-consulting`) ||
                    currenturl.includes(`/it-consulting-details`) ||
                    currenturl.includes(`/edit-it-consulting`) 
                      ? "nav-link text-white nav-link-active"
                      : "nav-link text-white"
                  }
                  to="/admin/it-consulting-list"
                >
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">tune </i>
                  </div>
                  <span className="nav-link-text ms-1">IT Consulting Service</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                className={
                  currenturl.includes(`/edit-about-data`) 
                    ? "nav-link text-white nav-link-active"
                    : "nav-link text-white"
                }
               to="/admin/about-data">
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">table_view</i>
                  </div>
                  <span className="nav-link-text ms-1">About Data</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                className={
                  currenturl.includes(`/banner-list`) ||
                  currenturl.includes(`/add-banner`) ||
                  currenturl.includes(`/edit-banner`) 
                    ? "nav-link text-white nav-link-active"
                    : "nav-link text-white"
                }
               to="/admin/banner-list">
                  <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10">table_view</i>
                  </div>
                  <span className="nav-link-text ms-1">Home Banner</span>
                </NavLink>
              </li>
             
            </ul>
          </div>
        </aside>
    {/* )}  */}
    </div>
  );
});

export default AdminSidebar;
