import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import auth from "./utill/auth";


// import AdminRoute from "./components/common/AdminRoute";
// import ApplicantRoute from "./components/common/ApplicantRoute";
// import PrivateRoute from "./components/common/PrivateRoute";
// import EditCertificate from "./components/profile/AddCertificate";
// import EditEducation from "./components/profile/AddEducation";
// import AddSkill from "./components/profile/AddSkill";
// import EditProfile from "./components/profile/EditProfile";
// import EmailChange from "./components/profile/EmailChange";

import ScrollToTop from "./css/ScrollToTop";

// import AddAddress from "./pages/Admin/AdminAddress/AddAddress";
// import AddressDetails from "./pages/Admin/AdminAddress/AddressDetails";
// import AddressList from "./pages/Admin/AdminAddress/AddressList";
// import EditAddress from "./pages/Admin/AdminAddress/EditAddress";
// import ApplicantList from "./pages/Admin/AdminApplicant/ApplicantList";
// import ApplicantRejectList from "./pages/Admin/AdminApplicant/ApplicantRejectList";
// import ShortListedApplicant from "./pages/Admin/AdminApplicant/ShortListedApplicant";
// import AddCategory from "./pages/Admin/AdminCategory/AddCategory";
// import AdminCategoryList from "./pages/Admin/AdminCategory/AdminCategoryList";
// import EditCategory from "./pages/Admin/AdminCategory/EditCategory";
// import AddConsultingStep from "./pages/Admin/AdminConsultingSteps/AddConsultingStep";
// import ConsultingStepDetails from "./pages/Admin/AdminConsultingSteps/ConsultingStepDetails";
// import ConsultingStepsList from "./pages/Admin/AdminConsultingSteps/ConsultingStepsList";
// import EditConsultingSteps from "./pages/Admin/AdminConsultingSteps/EditConsultingSteps";
// import AddContact from "./pages/Admin/AdminContact/AddContact";
// import ContactDetails from "./pages/Admin/AdminContact/ContactDetails";
// import ContactList from "./pages/Admin/AdminContact/ContactList";
// import EditContact from "./pages/Admin/AdminContact/EditContact";
// import AddExamQues from "./pages/Admin/AdminExamQuestion/AddExamQues";
// import ApplicantAnswerDetail from "./pages/Admin/AdminExamQuestion/ApplicantSubmitAnswer/ApplicantAnswerDetail";
// import ApplicantsAnswerList from "./pages/Admin/AdminExamQuestion/ApplicantSubmitAnswer/ApplicantsAnswerList";
// import EditExamQues from "./pages/Admin/AdminExamQuestion/EditExamQues";
// import ExamQuesDetails from "./pages/Admin/AdminExamQuestion/ExamQuesDetails";
// import ExamQuestionList from "./pages/Admin/AdminExamQuestion/ExamQuestionList";
import AddHomeBanner from "./pages/Admin/AdminHomeBanner/AddHomeBanner";
import EditHomeBanner from "./pages/Admin/AdminHomeBanner/EditHomeBanner";
import HomeBannerList from "./pages/Admin/AdminHomeBanner/HomeBannerList";
// import AddITConsulting from "./pages/Admin/AdminITConsulting/AddITConsulting";
// import EditITConsulting from "./pages/Admin/AdminITConsulting/EditITConsulting";
// import ITConsultingDetails from "./pages/Admin/AdminITConsulting/ITConsultingDetails";
// import ITServiceList from "./pages/Admin/AdminITConsulting/ITConsultingList";
// import AdminDetails from "./pages/Admin/AdminInfo/AdminDetails";
// import AddJob from "./pages/Admin/AdminJob/AddJob";
// import AdminJobDetails from "./pages/Admin/AdminJob/AdminJobDetails";
// import ArchivedJobList from "./pages/Admin/AdminJob/ArchivedJobList";
// import DraftedJobList from "./pages/Admin/AdminJob/DraftedJob/DraftedJobList";
// import EditJob from "./pages/Admin/AdminJob/EditJob";
// import JobList from "./pages/Admin/AdminJob/JobList";
// import AddNewsLetter from "./pages/Admin/AdminNewsLetter/AddNewsLetter";
// import EditNewsLetter from "./pages/Admin/AdminNewsLetter/EditNewsLetter";
// import NewsLetterList from "./pages/Admin/AdminNewsLetter/NewsLetterList";
// import NewsletterDetails from "./pages/Admin/AdminNewsLetter/NewsletterDetails";
// import AddNotice from "./pages/Admin/AdminNotice/AddNotice";
// import EditNotice from "./pages/Admin/AdminNotice/EditNotice";
// import NoticeDetails from "./pages/Admin/AdminNotice/NoticeDetails";
// import NoticeList from "./pages/Admin/AdminNotice/NoticeList";
// import EditOrder from "./pages/Admin/AdminOrder/EditOrder";
// import OrderDetails from "./pages/Admin/AdminOrder/OrderDetails";
// import OrderList from "./pages/Admin/AdminOrder/OrderList";
// import AddPartner from "./pages/Admin/AdminPartner/AddPartner";
// import EditPartner from "./pages/Admin/AdminPartner/EditPartner";
// import PartnerList from "./pages/Admin/AdminPartner/PartnerList";
// import AddProduct from "./pages/Admin/AdminProducts/AddProduct";
// import EditProduct from "./pages/Admin/AdminProducts/EditProduct";
// import ProductList from "./pages/Admin/AdminProducts/ProductList";
// import AddQuesType from "./pages/Admin/AdminQuestionType/AddQuesType";
// import EditQuesType from "./pages/Admin/AdminQuestionType/EditQuesType";
// import QuestionTypeList from "./pages/Admin/AdminQuestionType/QuestionTypeList";
// import AddService from "./pages/Admin/AdminServices/AddService";
// import AdminServiceDetails from "./pages/Admin/AdminServices/AdminServiceDetails";
// import EditService from "./pages/Admin/AdminServices/EditService";
// import SeviceList from "./pages/Admin/AdminServices/SeviceList";
// import AddSingleQues from "./pages/Admin/AdminSingleQuestion/AddSingleQues";
// import EditSingleQues from "./pages/Admin/AdminSingleQuestion/EditSingleQues";
// import SingleQuestionDetails from "./pages/Admin/AdminSingleQuestion/SingleQuestionDetails";
// import SingleQuestionList from "./pages/Admin/AdminSingleQuestion/SingleQuestionList";
// import AddSubcategory from "./pages/Admin/AdminSubcategory/AddSubcategory";
// import AdminSubcategoryList from "./pages/Admin/AdminSubcategory/AdminSubcategoryList";
// import EditSubcategory from "./pages/Admin/AdminSubcategory/EditSubcategory";
// import SubscriberList from "./pages/Admin/AdminSubscriber/SubscriberList";
// import AddMember from "./pages/Admin/AdminTeam/AddMember";
// import EditMember from "./pages/Admin/AdminTeam/EditMember";
// import TeamList from "./pages/Admin/AdminTeam/TeamList";
// import EditTestimonial from "./pages/Admin/AdminTestimonial/EditTestimonial";
// import TestimonialDetails from "./pages/Admin/AdminTestimonial/TestimonialDetails";
// import TestimonialList from "./pages/Admin/AdminTestimonial/TestimonialList";
// import AboutData from "./pages/Admin/ContentManagementSystem/AboutData/AboutData";
// import EditAboutData from "./pages/Admin/ContentManagementSystem/AboutData/EditAboutData";
// import AboutFeatureList from "./pages/Admin/ContentManagementSystem/AboutFeature/AboutFeatureList";
// import AddAboutFeature from "./pages/Admin/ContentManagementSystem/AboutFeature/AddAboutFeature";
// import EditAboutFeature from "./pages/Admin/ContentManagementSystem/AboutFeature/EditAboutFeature";
// import AddFooterContent from "./pages/Admin/ContentManagementSystem/FooterContent/AddFooterContent";
// import EditFooterContent from "./pages/Admin/ContentManagementSystem/FooterContent/EditFooterContent";
// import FooterContentList from "./pages/Admin/ContentManagementSystem/FooterContent/FooterContentList";
// import AddFooterHeader from "./pages/Admin/ContentManagementSystem/FooterHeader/AddFooterHeader";
// import EditFooterHeader from "./pages/Admin/ContentManagementSystem/FooterHeader/EditFooterHeader";
// import FooterHeaderList from "./pages/Admin/ContentManagementSystem/FooterHeader/FooterHeaderList";
// import AddHeaderMenu from "./pages/Admin/ContentManagementSystem/HeaderMenu/AddHeaderMenu";
// import EditHeaderMenu from "./pages/Admin/ContentManagementSystem/HeaderMenu/EditHeaderMenu";
// import HeaderMenuList from "./pages/Admin/ContentManagementSystem/HeaderMenu/HeaderMenuList";
// import AddPartnerCount from "./pages/Admin/ContentManagementSystem/PartnerCount/AddPartnerCount";
// import EditPartnerCount from "./pages/Admin/ContentManagementSystem/PartnerCount/EditPartnerCount";
// import PartnerCountList from "./pages/Admin/ContentManagementSystem/PartnerCount/PartnerCountList";
// import Dashboard from "./pages/Admin/Dashboard";
// import UserRole from "./pages/Admin/UserRole/UserRole";

// import Bookmark from "./pages/Bookmark";



// import Cart from "./pages/Cart";
// import Checkout from "./pages/Checkout";


// import Exam from "./pages/Exam";
// import ExamStart from "./pages/ExamStart";

// import GeneralApplyResume from "./pages/GeneralApplyResume";


// import Payment from "./pages/Payment";
// import PaymentFail from "./pages/PaymentFail";
// import PaymentSuccess from "./pages/PaymentSuccess";

// import Profile from "./pages/Profile";


const AdminRoute = lazy(() => import( "./components/common/AdminRoute"));
const ApplicantRoute = lazy(() => import( "./components/common/ApplicantRoute"));
const PrivateRoute = lazy(() => import( "./components/common/PrivateRoute"));
const EditCertificate = lazy(() => import( "./components/profile/AddCertificate"));
const EditEducation = lazy(() => import( "./components/profile/AddEducation"));
const AddSkill = lazy(() => import( "./components/profile/AddSkill"));
const EditProfile = lazy(() => import( "./components/profile/EditProfile"));
const EmailChange = lazy(() => import( "./components/profile/EmailChange"));
// const ScrollToTop = lazy(() => import( "./css/ScrollToTop"));
const AddAddress = lazy(() => import( "./pages/Admin/AdminAddress/AddAddress"));
const AddressDetails = lazy(() => import( "./pages/Admin/AdminAddress/AddressDetails"));
const AddressList = lazy(() => import( "./pages/Admin/AdminAddress/AddressList"));
const EditAddress = lazy(() => import( "./pages/Admin/AdminAddress/EditAddress"));
const ApplicantList = lazy(() => import( "./pages/Admin/AdminApplicant/ApplicantList"));
const ApplicantRejectList = lazy(() => import( "./pages/Admin/AdminApplicant/ApplicantRejectList"));
const ShortListedApplicant = lazy(() => import( "./pages/Admin/AdminApplicant/ShortListedApplicant"));
const AddCategory = lazy(() => import( "./pages/Admin/AdminCategory/AddCategory"));
const AdminCategoryList = lazy(() => import( "./pages/Admin/AdminCategory/AdminCategoryList"));
const EditCategory = lazy(() => import( "./pages/Admin/AdminCategory/EditCategory"));
const AddConsultingStep = lazy(() => import( "./pages/Admin/AdminConsultingSteps/AddConsultingStep"));
const ConsultingStepDetails = lazy(() => import( "./pages/Admin/AdminConsultingSteps/ConsultingStepDetails"));
const ConsultingStepsList = lazy(() => import( "./pages/Admin/AdminConsultingSteps/ConsultingStepsList"));
const EditConsultingSteps = lazy(() => import( "./pages/Admin/AdminConsultingSteps/EditConsultingSteps"));
const AddContact = lazy(() => import( "./pages/Admin/AdminContact/AddContact"));
const ContactDetails = lazy(() => import( "./pages/Admin/AdminContact/ContactDetails"));
const ContactList = lazy(() => import( "./pages/Admin/AdminContact/ContactList"));
const EditContact = lazy(() => import( "./pages/Admin/AdminContact/EditContact"));
const AddExamQues = lazy(() => import( "./pages/Admin/AdminExamQuestion/AddExamQues"));
const ApplicantAnswerDetail = lazy(() => import( "./pages/Admin/AdminExamQuestion/ApplicantSubmitAnswer/ApplicantAnswerDetail"));
const ApplicantsAnswerList = lazy(() => import( "./pages/Admin/AdminExamQuestion/ApplicantSubmitAnswer/ApplicantsAnswerList"));
const EditExamQues = lazy(() => import( "./pages/Admin/AdminExamQuestion/EditExamQues"));
const ExamQuesDetails = lazy(() => import( "./pages/Admin/AdminExamQuestion/ExamQuesDetails"));
const ExamQuestionList = lazy(() => import( "./pages/Admin/AdminExamQuestion/ExamQuestionList"));
// const AddHomeBanner = lazy(() => import( "./pages/Admin/AdminHomeBanner/AddHomeBanner"));
// const EditHomeBanner = lazy(() => import( "./pages/Admin/AdminHomeBanner/EditHomeBanner"));
// const HomeBannerList = lazy(() => import( "./pages/Admin/AdminHomeBanner/HomeBannerList"));
const AddITConsulting = lazy(() => import( "./pages/Admin/AdminITConsulting/AddITConsulting"));
const EditITConsulting = lazy(() => import( "./pages/Admin/AdminITConsulting/EditITConsulting"));
const ITConsultingDetails = lazy(() => import( "./pages/Admin/AdminITConsulting/ITConsultingDetails"));
const ITServiceList = lazy(() => import( "./pages/Admin/AdminITConsulting/ITConsultingList"));
const AdminDetails = lazy(() => import( "./pages/Admin/AdminInfo/AdminDetails"));
const AddJob = lazy(() => import( "./pages/Admin/AdminJob/AddJob"));
const AdminJobDetails = lazy(() => import( "./pages/Admin/AdminJob/AdminJobDetails"));
const ArchivedJobList = lazy(() => import( "./pages/Admin/AdminJob/ArchivedJobList"));
const DraftedJobList = lazy(() => import( "./pages/Admin/AdminJob/DraftedJob/DraftedJobList"));
const EditJob = lazy(() => import( "./pages/Admin/AdminJob/EditJob"));
const JobList = lazy(() => import( "./pages/Admin/AdminJob/JobList"));
const AddNewsLetter = lazy(() => import( "./pages/Admin/AdminNewsLetter/AddNewsLetter"));
const EditNewsLetter = lazy(() => import( "./pages/Admin/AdminNewsLetter/EditNewsLetter"));
const NewsLetterList = lazy(() => import( "./pages/Admin/AdminNewsLetter/NewsLetterList"));
const NewsletterDetails = lazy(() => import( "./pages/Admin/AdminNewsLetter/NewsletterDetails"));
const AddNotice = lazy(() => import( "./pages/Admin/AdminNotice/AddNotice"));
const EditNotice = lazy(() => import( "./pages/Admin/AdminNotice/EditNotice"));
const NoticeDetails = lazy(() => import( "./pages/Admin/AdminNotice/NoticeDetails"));
const NoticeList = lazy(() => import( "./pages/Admin/AdminNotice/NoticeList"));
const EditOrder = lazy(() => import( "./pages/Admin/AdminOrder/EditOrder"));
const OrderDetails = lazy(() => import( "./pages/Admin/AdminOrder/OrderDetails"));
const OrderList = lazy(() => import( "./pages/Admin/AdminOrder/OrderList"));
const AddPartner = lazy(() => import( "./pages/Admin/AdminPartner/AddPartner"));
const EditPartner = lazy(() => import( "./pages/Admin/AdminPartner/EditPartner"));
const PartnerList = lazy(() => import( "./pages/Admin/AdminPartner/PartnerList"));
const AddProduct = lazy(() => import( "./pages/Admin/AdminProducts/AddProduct"));
const EditProduct = lazy(() => import( "./pages/Admin/AdminProducts/EditProduct"));
const ProductList = lazy(() => import( "./pages/Admin/AdminProducts/ProductList"));
const AddQuesType = lazy(() => import( "./pages/Admin/AdminQuestionType/AddQuesType"));
const EditQuesType = lazy(() => import( "./pages/Admin/AdminQuestionType/EditQuesType"));
const QuestionTypeList = lazy(() => import( "./pages/Admin/AdminQuestionType/QuestionTypeList"));
const AddService = lazy(() => import( "./pages/Admin/AdminServices/AddService"));
const AdminServiceDetails = lazy(() => import( "./pages/Admin/AdminServices/AdminServiceDetails"));
const EditService = lazy(() => import( "./pages/Admin/AdminServices/EditService"));
const SeviceList = lazy(() => import( "./pages/Admin/AdminServices/SeviceList"));
const AddSingleQues = lazy(() => import( "./pages/Admin/AdminSingleQuestion/AddSingleQues"));
const EditSingleQues = lazy(() => import( "./pages/Admin/AdminSingleQuestion/EditSingleQues"));
const SingleQuestionDetails = lazy(() => import( "./pages/Admin/AdminSingleQuestion/SingleQuestionDetails"));
const SingleQuestionList = lazy(() => import( "./pages/Admin/AdminSingleQuestion/SingleQuestionList"));
const AddSubcategory = lazy(() => import( "./pages/Admin/AdminSubcategory/AddSubcategory"));
const AdminSubcategoryList = lazy(() => import( "./pages/Admin/AdminSubcategory/AdminSubcategoryList"));
const EditSubcategory = lazy(() => import( "./pages/Admin/AdminSubcategory/EditSubcategory"));
const SubscriberList = lazy(() => import( "./pages/Admin/AdminSubscriber/SubscriberList"));
const AddMember = lazy(() => import( "./pages/Admin/AdminTeam/AddMember"));
const EditMember = lazy(() => import( "./pages/Admin/AdminTeam/EditMember"));
const TeamList = lazy(() => import( "./pages/Admin/AdminTeam/TeamList"));
const EditTestimonial = lazy(() => import( "./pages/Admin/AdminTestimonial/EditTestimonial"));
const TestimonialDetails = lazy(() => import( "./pages/Admin/AdminTestimonial/TestimonialDetails"));
const TestimonialList = lazy(() => import( "./pages/Admin/AdminTestimonial/TestimonialList"));
const AboutData = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutData/AboutData"));
const EditAboutData = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutData/EditAboutData"));
const AboutFeatureList = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutFeature/AboutFeatureList"));
const AddAboutFeature = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutFeature/AddAboutFeature"));
const EditAboutFeature = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutFeature/EditAboutFeature"));
const AddFooterContent = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterContent/AddFooterContent"));
const EditFooterContent = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterContent/EditFooterContent"));
const FooterContentList = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterContent/FooterContentList"));
const AddFooterHeader = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterHeader/AddFooterHeader"));
const EditFooterHeader = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterHeader/EditFooterHeader"));
const FooterHeaderList = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterHeader/FooterHeaderList"));
const AddHeaderMenu = lazy(() => import( "./pages/Admin/ContentManagementSystem/HeaderMenu/AddHeaderMenu"));
const EditHeaderMenu = lazy(() => import( "./pages/Admin/ContentManagementSystem/HeaderMenu/EditHeaderMenu"));
const HeaderMenuList = lazy(() => import( "./pages/Admin/ContentManagementSystem/HeaderMenu/HeaderMenuList"));
const AddPartnerCount = lazy(() => import( "./pages/Admin/ContentManagementSystem/PartnerCount/AddPartnerCount"));
const EditPartnerCount = lazy(() => import( "./pages/Admin/ContentManagementSystem/PartnerCount/EditPartnerCount"));
const PartnerCountList = lazy(() => import( "./pages/Admin/ContentManagementSystem/PartnerCount/PartnerCountList"));
const Dashboard = lazy(() => import( "./pages/Admin/Dashboard"));
const UserRole = lazy(() => import( "./pages/Admin/UserRole/UserRole"));
const Bookmark = lazy(() => import( "./pages/Bookmark"));
const Cart = lazy(() => import( "./pages/Cart"));
const Checkout = lazy(() => import( "./pages/Checkout"));
const Exam = lazy(() => import( "./pages/Exam"));
const ExamStart = lazy(() => import( "./pages/ExamStart"));
const GeneralApplyResume = lazy(() => import( "./pages/GeneralApplyResume"));
const Payment = lazy(() => import( "./pages/Payment"));
const PaymentFail = lazy(() => import( "./pages/PaymentFail"));
const PaymentSuccess = lazy(() => import( "./pages/PaymentSuccess"));
const Profile = lazy(() => import( "./pages/Profile"));

const LazyAbout = lazy(() => import("./pages/About"));
const LazyHome = lazy(() => import("./pages/Home"));
const Services = lazy(() => import( "./pages/Services"));
const Careers = lazy(() => import( "./pages/Careers"));
const Pricing = lazy(() => import( "./components/serviceDetails/Pricing"));
const CareerDetails = lazy(() => import( "./pages/CareerDetails"));
const QuickApplyResume = lazy(() => import( "./pages/QuickApplyResume"));
const Contact = lazy(() => import( "./pages/Contact"));
const Ideas = lazy(() => import( "./pages/Ideas"));
const DesignStudio = lazy(() => import( "./pages/DesignStudio"));
const Campaigns = lazy(() => import( "./pages/Campaigns"));
const SustainabilityCommitment = lazy(() => import( "./pages/SustainabilityCommitment"));
const HelpCenter = lazy(() => import( "./pages/HelpCenter"));
const Accessibility = lazy(() => import( "./pages/Accessibility"));
const Security = lazy(() => import( "./pages/Security"));
const ReturnPolicy = lazy(() => import( "./pages/ReturnPolicy"));
const TermsOfUse = lazy(() => import( "./pages/TermsOfUse"));
const Login = lazy(() => import( "./pages/Login"));
const Register = lazy(() => import( "./pages/Register"));
const ForgotPassword = lazy(() => import( "./pages/ForgotPassword"));
const TermsConditions = lazy(() => import( "./pages/TermsConditions"));
const Products = lazy(() => import( "./pages/Products"));
const ResetPassword = lazy(() => import( "./pages/ResetPassword"));
const PrivacyPolicy = lazy(() => import( "./pages/PrivacyPolicy"));
const Notice = lazy(() => import( "./pages/Notice"));
const ApplicantValidation = lazy(() => import( "./pages/ApplicantValidation"));
const ServiceDetails = lazy(() => import( "./pages/ServiceDetails"));
const ProductDetails = lazy(() => import( "./pages/ProductDetails"));

const RouteApp = () => {
  let authUser = auth.getUserInfo();
  // console.log(authUser);
  //console.log(  localStorage.getItem('quickApplicant'));

  return (
    <BrowserRouter>
             <Suspense fallback={<>Loading...</>}>

      <ScrollToTop>
         <Routes> 
          <Route exact path="/" element={ 
        <LazyHome />
      } />
          <Route path="/home" element={ 
        <LazyHome />
      } />
       {/* <Route exact path="/" element={<Home />} />
       <Route path="/home" element={<Home />} /> */}
       {/* <Route path="/about" element={<About />} /> */}
          <Route path="/about" element={ 
        <LazyAbout />
      } />
          
          
           <Route path="/services" element={ 
         <Services /> 
         } />
         <Route path="/careers" element={ 
         <Careers /> 
         } />
          <Route path="/pricing" element={<Pricing /> } />
          <Route path="/career-details/:id" element={<CareerDetails />} />
          <Route path="/quick-apply-resume" element={ <QuickApplyResume />} />
          <Route path="/contact" element={ <Contact />} />
          <Route path="/ideas" element={ <Ideas />} />
          <Route path="/design-studio" element={ <DesignStudio />} />
          <Route path="/campaigns" element={ <Campaigns />} />
          <Route path="/sustainability-commitment" element={ <SustainabilityCommitment />} />
          <Route path="/help-center" element={ <HelpCenter />} />
          <Route path="/accessibility" element={ <Accessibility />} />
          <Route path="/security" element={ <Security />} />
          <Route path="/return-policy" element={ <ReturnPolicy />} />
          <Route path="/terms-of-use" element={ <TermsOfUse />} />
          <Route path="/login" element={ <Login />} />
          <Route path="/register" element={ <Register />} />
          <Route path="/forgot-password" element={ <ForgotPassword />} />
          <Route path="/terms-conditions" element={ <TermsConditions />} />
          <Route path="/products" element={ <Products />} />
          {/*  */}
          <Route path="/reset-password" element={ <ResetPassword />} />
          <Route path="/privacy-policy" element={ <PrivacyPolicy />} />
          <Route path="/notice" element={ <Notice />} />
          <Route path="/applicant-validation" element={ <ApplicantValidation />} />
          <Route path="/service-details/:id" element={ <ServiceDetails />} />
          <Route path="/product-details/:id" element={ <ProductDetails />} />
          
        
          <Route element={<AdminRoute />}>
            {/* admin routes */}
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/user-role" element={<UserRole />} />
             <Route path="/admin/edit-product/:id" element={<EditProduct />} />
            <Route path="/admin/products" element={<ProductList />} />

            <Route path="/admin/add-product" element={<AddProduct />} />
            <Route path="/admin/edit-product/:id" element={<EditProduct />} />
            <Route path="/admin/service-list" element={<SeviceList />} />
            <Route
              path="/admin/service-details/:id"
              element={<AdminServiceDetails />}
            />
            <Route path="/admin/add-service" element={<AddService />} />
            <Route path="/admin/edit-service/:id" element={<EditService />} />
            <Route path="/admin/team-list" element={<TeamList />} />
            <Route path="/admin/add-member" element={<AddMember />} />
            <Route path="/admin/edit-member/:id" element={<EditMember />} />
            <Route
              path="/admin/testimonial-list"
              element={<TestimonialList />}
            />
            <Route
              path="/admin/testimonial-details/:id"
              element={<TestimonialDetails />}
            />
            <Route
              path="/admin/edit-testimonial/:id"
              element={<EditTestimonial />}
            />
            <Route
              path="/admin/applicant-list/:id"
              element={<ApplicantList />}
            />
            <Route
              path="/admin/short-listed-applicant"
              element={<ShortListedApplicant />}
            />
            <Route
              path="/admin/reject-listed-applicant"
              element={<ApplicantRejectList />}
            />
            <Route path="/admin/notice-list" element={<NoticeList />} />
            <Route
              path="/admin/notice-details/:id"
              element={<NoticeDetails />}
            />
            <Route path="/admin/add-notice" element={<AddNotice />} />
            <Route path="/admin/edit-notice/:id" element={<EditNotice />} />
            <Route path="/admin/contact-list" element={<ContactList />} />
            <Route
              path="/admin/contact-details/:id"
              element={<ContactDetails />}
            />
            <Route path="/admin/add-contact" element={<AddContact />} />
            <Route path="/admin/edit-contact/:id" element={<EditContact />} />
            <Route path="/admin/job-list" element={<JobList />} />
            <Route
              path="/admin/job-details/:id"
              element={<AdminJobDetails />}
            />
            <Route path="/admin/add-job" element={<AddJob />} />
            <Route path="/admin/edit-job/:id" element={<EditJob />} />
            <Route path="/admin/partner-list" element={<PartnerList />} />
            <Route path="/admin/add-partner" element={<AddPartner />} />
            <Route path="/admin/edit-partner/:id" element={<EditPartner />} />
            <Route path="/admin/address-list" element={<AddressList />} />
            <Route
              path="/admin/address-details/:id"
              element={<AddressDetails />}
            />
            <Route path="/admin/add-address" element={<AddAddress />} />
            <Route path="/admin/edit-address/:id" element={<EditAddress />} />
            <Route
              path="/admin/category-list"
              element={<AdminCategoryList />}
            />
            <Route path="/admin/add-category" element={<AddCategory />} />
            <Route path="/admin/edit-category/:id" element={<EditCategory />} />
            <Route
              path="/admin/subcategory-list"
              element={<AdminSubcategoryList />}
            />
            <Route path="/admin/add-subcategory" element={<AddSubcategory />} />
            <Route
              path="/admin/edit-subcategory/:id"
              element={<EditSubcategory />}
            />
            <Route
              path="/admin/single-question-list"
              element={<SingleQuestionList />}
            />
            <Route
              path="/admin/single-question-details/:id"
              element={<SingleQuestionDetails />}
            />
            <Route
              path="/admin/add-single-question"
              element={<AddSingleQues />}
            />
            <Route
              path="/admin/edit-single-question/:id"
              element={<EditSingleQues />}
            />
            <Route
              path="/admin/question-type-list"
              element={<QuestionTypeList />}
            />
            <Route path="/admin/add-question-type" element={<AddQuesType />} />
            <Route
              path="/admin/edit-question-type/:id"
              element={<EditQuesType />}
            />
            <Route
              path="/admin/exam-question-list"
              element={<ExamQuestionList />}
            />
            <Route
              path="/admin/exam-question-details/:id"
              element={<ExamQuesDetails />}
            />
            <Route path="/admin/add-exam-question" element={<AddExamQues />} />
            <Route
              path="/admin/edit-exam-question/:id"
              element={<EditExamQues />}
            />
            <Route
              path="/admin/applicants-submit-answer"
              element={<ApplicantsAnswerList />}
            />
            <Route
              path="/admin/applicantAns-details/:id"
              element={<ApplicantAnswerDetail />}
            />
            <Route
              path="/admin/footer-header-list"
              element={<FooterHeaderList />}
            />
            <Route
              path="/admin/add-footer-header"
              element={<AddFooterHeader />}
            />
            <Route
              path="/admin/edit-footer-header/:id"
              element={<EditFooterHeader />}
            />
            <Route
              path="/admin/footer-content-list"
              element={<FooterContentList />}
            />
            <Route
              path="/admin/add-footer-content"
              element={<AddFooterContent />}
            />
            <Route
              path="/admin/edit-footer-content/:id"
              element={<EditFooterContent />}
            />
            <Route
              path="/admin/header-menu-list"
              element={<HeaderMenuList />}
            />
            <Route path="/admin/add-header-menu" element={<AddHeaderMenu />} />

            <Route
              path="/admin/edit-header-menu/:id"
              element={<EditHeaderMenu />}
            />
            {/* <Route path="/admin/posted-job-list" element={<PostedJobList />} /> */}
            <Route
              path="/admin/drafted-job-list"
              element={<DraftedJobList />}
            />
            <Route
              path="/admin/archived-job-list"
              element={<ArchivedJobList />}
            />
            <Route
              path="/admin/about-feature-list"
              element={<AboutFeatureList />}
            />
            <Route
              path="/admin/add-about-feature"
              element={<AddAboutFeature />}
            />
            <Route
              path="/admin/edit-about-feature/:id"
              element={<EditAboutFeature />}
            />
            <Route
              path="/admin/partner-count-list"
              element={<PartnerCountList />}
            />
            <Route
              path="/admin/add-partner-count"
              element={<AddPartnerCount />}
            />
            <Route
              path="/admin/edit-partner-count/:id"
              element={<EditPartnerCount />}
            />
            <Route
              path="/admin/news-letter-list"
              element={<NewsLetterList />}
            />
            <Route path="/admin/add-news-letter" element={<AddNewsLetter />} />
            <Route
              path="/admin/edit-news-letter/:id"
              element={<EditNewsLetter />}
            />
            <Route
              path="/admin/newsletter-details/:id"
              element={<NewsletterDetails />}
            />
            <Route path="/admin/order-list" element={<OrderList />} />
            <Route path="/admin/order-details/:id" element={<OrderDetails />} />
            <Route path="/admin/edit-order/:id" element={<EditOrder />} />
            <Route path="/admin/subscribe-list" element={<SubscriberList />} />

            <Route
              path="/admin/consulting-step-list"
              element={<ConsultingStepsList />}
            />
            <Route
              path="/admin/add-consulting-step"
              element={<AddConsultingStep />}
            />
            <Route
              path="/admin/edit-consulting-step/:id"
              element={<EditConsultingSteps />}
            />
            <Route
              path="/admin/consulting-step-details/:id"
              element={<ConsultingStepDetails />}
            />
            <Route
              path="/admin/it-consulting-list"
              element={<ITServiceList />}
            />
            <Route
              path="/admin/add-it-consulting"
              element={<AddITConsulting />}
            />
            <Route
              path="/admin/edit-it-consulting/:id"
              element={<EditITConsulting />}
            />
            <Route
              path="/admin/it-consulting-details/:id"
              element={<ITConsultingDetails />}
            />
            <Route path="/admin/banner-list" element={<HomeBannerList />} />
            <Route path="/admin/add-banner" element={<AddHomeBanner />} />
            <Route path="/admin/edit-banner/:id" element={<EditHomeBanner />} />
            <Route path="/admin/about-data" element={<AboutData />} />
            <Route path="/admin/edit-about-data" element={<EditAboutData />} />
            <Route path="/admin/admin-details/:id" element={<AdminDetails />} />
          </Route>

          <Route element={<ApplicantRoute />}>
            {/* <Route
            path="/general-apply-resume"
            element={<GeneralApplyResume />}
          /> */}
          </Route>

          <Route element={<PrivateRoute />}>
        
            <Route path="payment" element={<Payment />} />
            <Route path="payment-success" element={<PaymentSuccess />} />
            <Route path="payment-fail" element={<PaymentFail />} />

            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/bookmark" element={<Bookmark />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/edit-skill" element={<AddSkill />} />
            <Route path="/edit-edu" element={<EditEducation />} />
            <Route path="/edit-certificate" element={<EditCertificate />} />
            <Route path="email-change" element={<EmailChange />} />

            {(authUser?.id ||
              localStorage.getItem("quickApplicant") === true) && (
              <>
                <Route path="/exam-start" element={<ExamStart />} />
                <Route path="/exam" element={<Exam />} />
                <Route
                  path="/general-apply-resume"
                  element={<GeneralApplyResume />}
                />
              </>
            )}
          </Route>
      
        </Routes>
      </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
};

export default RouteApp;


// const AdminRoute = lazy(() => import( "./components/common/AdminRoute"));
// const ApplicantRoute = lazy(() => import( "./components/common/ApplicantRoute"));
// const PrivateRoute = lazy(() => import( "./components/common/PrivateRoute"));
// const EditCertificate = lazy(() => import( "./components/profile/AddCertificate"));
// const EditEducation = lazy(() => import( "./components/profile/AddEducation"));
// const AddSkill = lazy(() => import( "./components/profile/AddSkill"));
// const EditProfile = lazy(() => import( "./components/profile/EditProfile"));
// const EmailChange = lazy(() => import( "./components/profile/EmailChange"));
// const ScrollToTop = lazy(() => import( "./css/ScrollToTop"));
// const AddAddress = lazy(() => import( "./pages/Admin/AdminAddress/AddAddress"));
// const AddressDetails = lazy(() => import( "./pages/Admin/AdminAddress/AddressDetails"));
// const AddressList = lazy(() => import( "./pages/Admin/AdminAddress/AddressList"));
// const EditAddress = lazy(() => import( "./pages/Admin/AdminAddress/EditAddress"));
// const ApplicantList = lazy(() => import( "./pages/Admin/AdminApplicant/ApplicantList"));
// const ApplicantRejectList = lazy(() => import( "./pages/Admin/AdminApplicant/ApplicantRejectList"));
// const ShortListedApplicant = lazy(() => import( "./pages/Admin/AdminApplicant/ShortListedApplicant"));
// const AddCategory = lazy(() => import( "./pages/Admin/AdminCategory/AddCategory"));
// const AdminCategoryList = lazy(() => import( "./pages/Admin/AdminCategory/AdminCategoryList"));
// const EditCategory = lazy(() => import( "./pages/Admin/AdminCategory/EditCategory"));
// const AddConsultingStep = lazy(() => import( "./pages/Admin/AdminConsultingSteps/AddConsultingStep"));
// const ConsultingStepDetails = lazy(() => import( "./pages/Admin/AdminConsultingSteps/ConsultingStepDetails"));
// const ConsultingStepsList = lazy(() => import( "./pages/Admin/AdminConsultingSteps/ConsultingStepsList"));
// const EditConsultingSteps = lazy(() => import( "./pages/Admin/AdminConsultingSteps/EditConsultingSteps"));
// const AddContact = lazy(() => import( "./pages/Admin/AdminContact/AddContact"));
// const ContactDetails = lazy(() => import( "./pages/Admin/AdminContact/ContactDetails"));
// const ContactList = lazy(() => import( "./pages/Admin/AdminContact/ContactList"));
// const EditContact = lazy(() => import( "./pages/Admin/AdminContact/EditContact"));
// const AddExamQues = lazy(() => import( "./pages/Admin/AdminExamQuestion/AddExamQues"));
// const ApplicantAnswerDetail = lazy(() => import( "./pages/Admin/AdminExamQuestion/ApplicantSubmitAnswer/ApplicantAnswerDetail"));
// const ApplicantsAnswerList = lazy(() => import( "./pages/Admin/AdminExamQuestion/ApplicantSubmitAnswer/ApplicantsAnswerList"));
// const EditExamQues = lazy(() => import( "./pages/Admin/AdminExamQuestion/EditExamQues"));
// const ExamQuesDetails = lazy(() => import( "./pages/Admin/AdminExamQuestion/ExamQuesDetails"));
// const ExamQuestionList = lazy(() => import( "./pages/Admin/AdminExamQuestion/ExamQuestionList"));
// const AddHomeBanner = lazy(() => import( "./pages/Admin/AdminHomeBanner/AddHomeBanner"));
// const EditHomeBanner = lazy(() => import( "./pages/Admin/AdminHomeBanner/EditHomeBanner"));
// const HomeBannerList = lazy(() => import( "./pages/Admin/AdminHomeBanner/HomeBannerList"));
// const AddITConsulting = lazy(() => import( "./pages/Admin/AdminITConsulting/AddITConsulting"));
// const EditITConsulting = lazy(() => import( "./pages/Admin/AdminITConsulting/EditITConsulting"));
// const ITConsultingDetails = lazy(() => import( "./pages/Admin/AdminITConsulting/ITConsultingDetails"));
// const ITServiceList = lazy(() => import( "./pages/Admin/AdminITConsulting/ITConsultingList"));
// const AdminDetails = lazy(() => import( "./pages/Admin/AdminInfo/AdminDetails"));
// const AddJob = lazy(() => import( "./pages/Admin/AdminJob/AddJob"));
// const AdminJobDetails = lazy(() => import( "./pages/Admin/AdminJob/AdminJobDetails"));
// const ArchivedJobList = lazy(() => import( "./pages/Admin/AdminJob/ArchivedJobList"));
// const DraftedJobList = lazy(() => import( "./pages/Admin/AdminJob/DraftedJob/DraftedJobList"));
// const EditJob = lazy(() => import( "./pages/Admin/AdminJob/EditJob"));
// const JobList = lazy(() => import( "./pages/Admin/AdminJob/JobList"));
// const AddNewsLetter = lazy(() => import( "./pages/Admin/AdminNewsLetter/AddNewsLetter"));
// const EditNewsLetter = lazy(() => import( "./pages/Admin/AdminNewsLetter/EditNewsLetter"));
// const NewsLetterList = lazy(() => import( "./pages/Admin/AdminNewsLetter/NewsLetterList"));
// const NewsletterDetails = lazy(() => import( "./pages/Admin/AdminNewsLetter/NewsletterDetails"));
// const AddNotice = lazy(() => import( "./pages/Admin/AdminNotice/AddNotice"));
// const EditNotice = lazy(() => import( "./pages/Admin/AdminNotice/EditNotice"));
// const NoticeDetails = lazy(() => import( "./pages/Admin/AdminNotice/NoticeDetails"));
// const NoticeList = lazy(() => import( "./pages/Admin/AdminNotice/NoticeList"));
// const EditOrder = lazy(() => import( "./pages/Admin/AdminOrder/EditOrder"));
// const OrderDetails = lazy(() => import( "./pages/Admin/AdminOrder/OrderDetails"));
// const OrderList = lazy(() => import( "./pages/Admin/AdminOrder/OrderList"));
// const AddPartner = lazy(() => import( "./pages/Admin/AdminPartner/AddPartner"));
// const EditPartner = lazy(() => import( "./pages/Admin/AdminPartner/EditPartner"));
// const PartnerList = lazy(() => import( "./pages/Admin/AdminPartner/PartnerList"));
// const AddProduct = lazy(() => import( "./pages/Admin/AdminProducts/AddProduct"));
// const EditProduct = lazy(() => import( "./pages/Admin/AdminProducts/EditProduct"));
// const ProductList = lazy(() => import( "./pages/Admin/AdminProducts/ProductList"));
// const AddQuesType = lazy(() => import( "./pages/Admin/AdminQuestionType/AddQuesType"));
// const EditQuesType = lazy(() => import( "./pages/Admin/AdminQuestionType/EditQuesType"));
// const QuestionTypeList = lazy(() => import( "./pages/Admin/AdminQuestionType/QuestionTypeList"));
// const AddService = lazy(() => import( "./pages/Admin/AdminServices/AddService"));
// const AdminServiceDetails = lazy(() => import( "./pages/Admin/AdminServices/AdminServiceDetails"));
// const EditService = lazy(() => import( "./pages/Admin/AdminServices/EditService"));
// const SeviceList = lazy(() => import( "./pages/Admin/AdminServices/SeviceList"));
// const AddSingleQues = lazy(() => import( "./pages/Admin/AdminSingleQuestion/AddSingleQues"));
// const EditSingleQues = lazy(() => import( "./pages/Admin/AdminSingleQuestion/EditSingleQues"));
// const SingleQuestionDetails = lazy(() => import( "./pages/Admin/AdminSingleQuestion/SingleQuestionDetails"));
// const SingleQuestionList = lazy(() => import( "./pages/Admin/AdminSingleQuestion/SingleQuestionList"));
// const AddSubcategory = lazy(() => import( "./pages/Admin/AdminSubcategory/AddSubcategory"));
// const AdminSubcategoryList = lazy(() => import( "./pages/Admin/AdminSubcategory/AdminSubcategoryList"));
// const EditSubcategory = lazy(() => import( "./pages/Admin/AdminSubcategory/EditSubcategory"));
// const SubscriberList = lazy(() => import( "./pages/Admin/AdminSubscriber/SubscriberList"));
// const AddMember = lazy(() => import( "./pages/Admin/AdminTeam/AddMember"));
// const EditMember = lazy(() => import( "./pages/Admin/AdminTeam/EditMember"));
// const TeamList = lazy(() => import( "./pages/Admin/AdminTeam/TeamList"));
// const EditTestimonial = lazy(() => import( "./pages/Admin/AdminTestimonial/EditTestimonial"));
// const TestimonialDetails = lazy(() => import( "./pages/Admin/AdminTestimonial/TestimonialDetails"));
// const TestimonialList = lazy(() => import( "./pages/Admin/AdminTestimonial/TestimonialList"));
// const AboutData = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutData/AboutData"));
// const EditAboutData = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutData/EditAboutData"));
// const AboutFeatureList = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutFeature/AboutFeatureList"));
// const AddAboutFeature = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutFeature/AddAboutFeature"));
// const EditAboutFeature = lazy(() => import( "./pages/Admin/ContentManagementSystem/AboutFeature/EditAboutFeature"));
// const AddFooterContent = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterContent/AddFooterContent"));
// const EditFooterContent = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterContent/EditFooterContent"));
// const FooterContentList = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterContent/FooterContentList"));
// const AddFooterHeader = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterHeader/AddFooterHeader"));
// const EditFooterHeader = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterHeader/EditFooterHeader"));
// const FooterHeaderList = lazy(() => import( "./pages/Admin/ContentManagementSystem/FooterHeader/FooterHeaderList"));
// const AddHeaderMenu = lazy(() => import( "./pages/Admin/ContentManagementSystem/HeaderMenu/AddHeaderMenu"));
// const EditHeaderMenu = lazy(() => import( "./pages/Admin/ContentManagementSystem/HeaderMenu/EditHeaderMenu"));
// const HeaderMenuList = lazy(() => import( "./pages/Admin/ContentManagementSystem/HeaderMenu/HeaderMenuList"));
// const AddPartnerCount = lazy(() => import( "./pages/Admin/ContentManagementSystem/PartnerCount/AddPartnerCount"));
// const EditPartnerCount = lazy(() => import( "./pages/Admin/ContentManagementSystem/PartnerCount/EditPartnerCount"));
// const PartnerCountList = lazy(() => import( "./pages/Admin/ContentManagementSystem/PartnerCount/PartnerCountList"));
// const Dashboard = lazy(() => import( "./pages/Admin/Dashboard"));
// const UserRole = lazy(() => import( "./pages/Admin/UserRole/UserRole"));
// const Bookmark = lazy(() => import( "./pages/Bookmark"));
// const Cart = lazy(() => import( "./pages/Cart"));
// const Checkout = lazy(() => import( "./pages/Checkout"));
// const Exam = lazy(() => import( "./pages/Exam"));
// const ExamStart = lazy(() => import( "./pages/ExamStart"));
// const GeneralApplyResume = lazy(() => import( "./pages/GeneralApplyResume"));
// const Payment = lazy(() => import( "./pages/Payment"));
// const PaymentFail = lazy(() => import( "./pages/PaymentFail"));
// const PaymentSuccess = lazy(() => import( "./pages/PaymentSuccess"));
// const Profile = lazy(() => import( "./pages/Profile"));

// const LazyAbout = lazy(() => import("./pages/About"));
// const LazyHome = lazy(() => import("./pages/Home"));
// const Services = lazy(() => import( "./pages/Services"));
// const Careers = lazy(() => import( "./pages/Careers"));
// const Pricing = lazy(() => import( "./components/serviceDetails/Pricing"));
// const CareerDetails = lazy(() => import( "./pages/CareerDetails"));
// const QuickApplyResume = lazy(() => import( "./pages/QuickApplyResume"));
// const Contact = lazy(() => import( "./pages/Contact"));
// const Ideas = lazy(() => import( "./pages/Ideas"));
// const DesignStudio = lazy(() => import( "./pages/DesignStudio"));
// const Campaigns = lazy(() => import( "./pages/Campaigns"));
// const SustainabilityCommitment = lazy(() => import( "./pages/SustainabilityCommitment"));
// const HelpCenter = lazy(() => import( "./pages/HelpCenter"));
// const Accessibility = lazy(() => import( "./pages/Accessibility"));
// const Security = lazy(() => import( "./pages/Security"));
// const ReturnPolicy = lazy(() => import( "./pages/ReturnPolicy"));
// const TermsOfUse = lazy(() => import( "./pages/TermsOfUse"));
// const Login = lazy(() => import( "./pages/Login"));
// const Register = lazy(() => import( "./pages/Register"));
// const ForgotPassword = lazy(() => import( "./pages/ForgotPassword"));
// const TermsConditions = lazy(() => import( "./pages/TermsConditions"));
// const Products = lazy(() => import( "./pages/Products"));
// const ResetPassword = lazy(() => import( "./pages/ResetPassword"));
// const PrivacyPolicy = lazy(() => import( "./pages/PrivacyPolicy"));
// const Notice = lazy(() => import( "./pages/Notice"));
// const ApplicantValidation = lazy(() => import( "./pages/ApplicantValidation"));
// const ServiceDetails = lazy(() => import( "./pages/ServiceDetails"));
// const ProductDetails = lazy(() => import( "./pages/ProductDetails"));