import { combineReducers } from 'redux';
import { cartReducer } from './cartReducer';
import contactReducer from './contactReducer';
import footerReducer from './footerReducer';
import headerReducer from './headerReducer';
import homeReducer from './homeReducer';
import { orderReducer } from './orderReducer';
import serviceReducer from './serviceReducer';
import { toggleReducer } from './toggleReducer';

const reduces = combineReducers({
    cart: cartReducer,
    order: orderReducer,
    toggleBtn: toggleReducer,
    header: headerReducer,
    footerHeader: footerReducer,
    home: homeReducer,
    services: serviceReducer,
    address: contactReducer

})
export default reduces;