// cart
export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const REMOVE_All_ITEM = "REMOVE_All_ITEM"
export const INCREASE_CART_ITEM = "INCREASE_CART_ITEM"
export const DECREASE_CART_ITEM = "DECREASE_CART_ITEM"

//order
export const UPDATE_TOTAL_PRICE = "UPDATE_TOTAL_PRICE"

//admin sidebar
export const Toggle_BUTTON = "Toggle_BUTTON"

// shipping address
export const ADD_SHIPPING_ADDRESS = "ADD_SHIPPING_ADDRESS"

//header
export const FETCH_HEADER_MENU = "FETCH_HEADER_MENU"
export const FETCH_HEADER_MENU_SUCCESS = "FETCH_HEADER_MENU_SUCCESS"
export const FETCH_HEADER_MENU_FAILURE = "FETCH_HEADER_MENU_FAILURE"
export const FETCH_FOOTER_HEADER_SUCCESS = "FETCH_FOOTER_HEADER_SUCCESS"

// home
export const FETCH_HOME_BANNER = "FETCH_HOME_BANNER"
export const FETCH_PARTNER_COUNT = "FETCH_PARTNER_COUNT"
export const FETCH_ABOUT_COMPANY = "FETCH_ABOUT_COMPANY"
export const FETCH_ABOUT_FEATURE = "FETCH_ABOUT_FEATURE"
export const FETCH_HOME_SERVICE = "FETCH_HOME_SERVICE"
export const FETCH_OUR_PRODUCT = "FETCH_OUR_PRODUCT"
export const FETCH_CLIENT_SAYS = "FETCH_CLIENT_SAYS"
export const FETCH_TEAM_MEMBER = "FETCH_TEAM_MEMBER"


// services

export const FETCH_ALL_SERVICES = "FETCH_ALL_SERVICES"
export const FETCH_CONSULTING_STEPS = "FETCH_CONSULTING_STEPS"
export const FETCH_IT_CONSULTING_STEPS = "FETCH_IT_CONSULTING_STEPS"

// products
export const FETCH_ALL_PRODUCT= "FETCH_ALL_PRODUCT"
export const FETCH_CATEGORIES_NAME= "FETCH_CATEGORIES_NAME"

// contact
export const FETCH_COMPANY_ADDRESS=  "FETCH_COMPANY_ADDRESS"

// admin

export const FETCH_ADMIN_LIST=  "FETCH_ADMIN_LIST"
export const FETCH_PRODUCT_LIST=  "FETCH_PRODUCT_LIST"
export const FETCH_SERVICE_LIST=  "FETCH_SERVICE_LIST"
export const FETCH_MEMBER_LIST=  "FETCH_MEMBER_LIST"