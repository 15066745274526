import React, { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import Select from "react-select";
const customStyle = {
  control: (base, state, styles) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    ...styles,
    // backgroundColor: "black",
  }),
  input: (provided, state) => ({
    ...provided,
    height: 35,
  }),
};
const BannerCreateUpdateForm = memo(({onSubmit, banner, edit, bannerBody, setbannerBody, serviceList}) => {
    const {
        handleSubmit,
        formState: { errors },
        register,
        setValue,
        watch, control,
      } = useForm();
      
      const [serviceOptions, setserviceOptions] = useState([]);
  useEffect(() => {
    const options = [];
    serviceList?.data?.map((option) => 
      options.push({ value: option.id, label: option.name })
    );

    setserviceOptions(options);
  }, [serviceList]);
      const [imagePreview, setImagePreview] = useState(null);
      if (watch("photo")?.length > 0) {
        try {
          let watchImage = watch("photo");
          const reader = new FileReader();
          reader.readAsDataURL(watchImage[0]);
          reader.onload = function () {
            setImagePreview(reader.result);
          };
        } catch (e) {
        }
      }

      useEffect(() => {
        if (banner) {
          setbannerBody(banner?.body);
          setValue("title", banner.title);
          setValue("sub_title", banner.sub_title);
          if (serviceOptions?.length > 0) {
            let selectedOption = serviceOptions.find(
              (item) => parseInt(item.value) === parseInt(banner.service_id)
            );
           console.log(selectedOption?.value, selectedOption?.label);
            setValue("service_id", {
              value: selectedOption?.value,
              label: selectedOption?.label,
            });
          }
        }
      }, [banner, setValue, serviceOptions, setbannerBody]);

      const handleQuesBodyChange = (event) => {
        setbannerBody(event);
      };
  return (
   
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <div className="row g-2">
        <div className="col-md-6 col-sm-12">
          <label className=" text-black text-capitalize">title</label>
          <br />
          <input
            {...register("title", { required: true })}
            type="text"
            id="title"
            className="w-75 border border-1 rounded p-2"
          />
          {errors.title && (
            <p className="text-danger">This field is required</p>
          )}
        </div>
        <div className="col-md-6 col-sm-12">
            <label htmlFor="service" className=" text-black text-capitalize">
              service
            </label>
            <Controller
              name="service_id"
              render={({ field }) => (
                <Select
                  {...field}
                  className="w-75 border border-1 rounded"
                  styles={customStyle}
                  components={{
                    // DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  options={serviceOptions}
                  id=""
                />
              )}
              control={control}
              rules={{ required: true }}
            />
            {errors.service && errors.service.type === "required" && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
        <div className="col-md-6 col-sm-12">
          <label
            className=" text-black text-capitalize"
          >
            subtitle
          </label>
          <br />
          <input
            {...register("sub_title", { required: true })}
            type="text"
            id="sub_title"
            className="w-75 border border-1 rounded p-2"
          />
          {errors.sub_title && (
            <p className="text-danger">This field is required</p>
          )}
        </div>
        <div className="col-md-10 col-sm-12 ">
            <label htmlFor="Ques" className=" text-black text-capitalize">
              Description
            </label>
            <br />

            <ReactQuill
              style={{ backgroundColor: "#fff" }}
              value={bannerBody}
              onChange={handleQuesBodyChange}
              className="mt-1"
              modules={BannerCreateUpdateForm.modules}
              formats={BannerCreateUpdateForm.formats}
              clipboard={BannerCreateUpdateForm.clipboard}
              preserveWhitespace
            />
          </div>
        <div className="col-md-6 col-sm-12">
          <label className=" text-black text-capitalize">Photo</label>
          <br />
          {imagePreview ? (
            <img loading="lazy" src={imagePreview} alt="preview" className="w-50" />
          ) : null}
          {!imagePreview && banner ? (
            <img loading="lazy"
              src={`${process.env.REACT_APP_BACKEND_URL}/${banner.photo}`}
              alt="preview"
              className="w-50"
            />
          ) : null}
          <input
            {...register("photo", { required: edit ? false : true })}
            type="file"
            className="p-3"
          />
          {errors.photo && (
            <p className="text-danger">This field is required</p>
          )}
        </div>

        {/* <div className="col-md-6 col-sm-12">
          <label className=" text-black text-capitalize">featured</label>
          <br />
          <input {...register("featured")} type="checkbox" id="featured" />
        </div> */}
      </div>

      <div className=" mt-2 cursor-pointer">
        <button
          type="submit"
          className="bg-gradient-primary text-white border-radius-lg px-4 py-2"
        >
          Submit
        </button>
      </div>
    </form>
 
  )
})

BannerCreateUpdateForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
BannerCreateUpdateForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
export default BannerCreateUpdateForm