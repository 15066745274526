import * as actionType from '../types';
function getCartFromLocalStorage() {
    return localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : [];
  }
 
  const initialStateCart = {
    isPending: false,
    cart: getCartFromLocalStorage(),
    error: "",
  };

  export const cartReducer = (state = initialStateCart, action = {}) => {
    switch (action.type) {
     
      case actionType.ADD_TO_CART:
      //   let cart = state.cart
      //   localStorage.setItem("cart", JSON.stringify(cart))
      //   return {
      //           ...state,
      //           cart: [...state.cart, action.payload]
      //       };
        let cart = state.cart;
        let { id, name, price,discount_price, discount_start_date, discount_end_date, photo, quantity = 1, discount = false } = action.payload;
        //console.log(action.payload)
        const today = new Date().toJSON().slice(0, 10);
        let cost = price;

        if(today >= discount_start_date && today <= discount_end_date){
          discount = true;
          cost = discount_price
        }
       
        const item = [...state.cart].find((item) => item.id === id);
        if (item) {
          const incsCart = [...state.cart].map((item) => {
            return item.id === id
              ? { ...item, quantity: item.quantity + quantity 
              }
              : { ...item };
          });
          localStorage.setItem("cart", JSON.stringify(incsCart));
          return { ...state, cart: incsCart };
        } else {
          const newItem2 = {
            id,
            name,
            photo,
            price: parseFloat(cost),
            // discount_price: parseFloat(discount_price),
            // discount_start_date, discount_end_date,
            quantity: quantity,
          discount: discount,
          
          };
          cart.push(newItem2);
          localStorage.setItem("cart", JSON.stringify(cart));
         //console.log(cart);
          return {
            ...state,
            cart: cart,
  
          };
        }
      

      case actionType.REMOVE_FROM_CART:
          // return {...state, cart: state.cart.filter(item => item.id !== action.payload.id)}
         //console.log(action.payload)
          let newCart = [...state.cart].filter(
        (item) => item.id !== action.payload
      );
      localStorage.setItem("cart", JSON.stringify(newCart));
      return { ...state, cart: newCart };
          
      case actionType.REMOVE_All_ITEM:
        localStorage.setItem("cart", JSON.stringify([]));
        return { ...state, cart: [] };

      case actionType.INCREASE_CART_ITEM:
        //console.log(action.payload)
        const incCart = [...state.cart].map((item) => {
          return item.id === action.payload
            ? { ...item, quantity: item.quantity + 1 }
            : { ...item };
        });
        localStorage.setItem("cart", JSON.stringify(incCart));
        return { ...state, cart: incCart };
      case actionType.DECREASE_CART_ITEM:
        //console.log(action.payload)
        const decCart = [...state.cart].map((item) => {
          return item.id === action.payload
            ? { ...item, quantity: item.quantity - 1 }
            : { ...item };
        });
        localStorage.setItem("cart", JSON.stringify(decCart));
        return { ...state, cart: decCart };
      default:
        return state;
    }
  };

